
import React, {Component} from 'react'
import './MapComponent.css';
import '../../node_modules/leaflet/dist/leaflet.css';
import L from 'leaflet';
import "leaflet.markercluster";
import MapRepository from './classMapRepository.js'


const HARITA_DATA = {
  "KONUMLAR": [
      {
          "ENLEM": 38.396711,
          "BOYLAM": 27.035818,
          "RENK": "#1345aa",
          "ACIKLAMA": "ACIKLAMA 1",
          "TANIM": null
      },
      {
          "ENLEM": 38.39587,
          "BOYLAM": 27.019811,
          "RENK": "#FD0E35",
          "ACIKLAMA": "ACIKLAMA 2",
          "TANIM": null
      },
      {
          "ENLEM": 38.399166,
          "BOYLAM": 27.065344,
          "RENK": "#ccbea5",
          "ACIKLAMA": "ACIKLAMA 2",
          "TANIM": null
      },
      {
          "ENLEM": 38.396711,
          "BOYLAM": 27.035818,
          "RENK": "#1345aa",
          "ACIKLAMA": "ACIKLAMA 4",
          "TANIM": null
      },
      {
          "ENLEM": 38.39587,
          "BOYLAM": 27.019811,
          "RENK": "#FD0E35",
          "ACIKLAMA": "ACIKLAMA 5",
          "TANIM": null
      },
      {
          "ENLEM": 38.399166,
          "BOYLAM": 27.065344,
          "RENK": "#ccbea5",
          "ACIKLAMA": "ACIKLAMA 6",
          "TANIM": null
      }
  ],
  "LISTE": [
      {
          "TEXT": "Deneme 1 2",
          "RENK": "#1345aa"
      },
      {
          "TEXT": "Deneme 2 2",
          "RENK": "#FD0E35"
      },
      {
          "TEXT": "Deneme 3 2",
          "RENK": "#ccbea5"
      }
  ]
};

const gear = <svg className="gear" viewBox="0 0 32 32">
<path d="M11.366 22.564l1.291-1.807-1.414-1.414-1.807 1.291c-0.335-0.187-0.694-0.337-1.071-0.444l-0.365-2.19h-2l-0.365 2.19c-0.377 0.107-0.736 0.256-1.071 0.444l-1.807-1.291-1.414 1.414 1.291 1.807c-0.187 0.335-0.337 0.694-0.443 1.071l-2.19 0.365v2l2.19 0.365c0.107 0.377 0.256 0.736 0.444 1.071l-1.291 1.807 1.414 1.414 1.807-1.291c0.335 0.187 0.694 0.337 1.071 0.444l0.365 2.19h2l0.365-2.19c0.377-0.107 0.736-0.256 1.071-0.444l1.807 1.291 1.414-1.414-1.291-1.807c0.187-0.335 0.337-0.694 0.444-1.071l2.19-0.365v-2l-2.19-0.365c-0.107-0.377-0.256-0.736-0.444-1.071zM7 27c-1.105 0-2-0.895-2-2s0.895-2 2-2 2 0.895 2 2-0.895 2-2 2zM32 12v-2l-2.106-0.383c-0.039-0.251-0.088-0.499-0.148-0.743l1.799-1.159-0.765-1.848-2.092 0.452c-0.132-0.216-0.273-0.426-0.422-0.629l1.219-1.761-1.414-1.414-1.761 1.219c-0.203-0.149-0.413-0.29-0.629-0.422l0.452-2.092-1.848-0.765-1.159 1.799c-0.244-0.059-0.492-0.109-0.743-0.148l-0.383-2.106h-2l-0.383 2.106c-0.251 0.039-0.499 0.088-0.743 0.148l-1.159-1.799-1.848 0.765 0.452 2.092c-0.216 0.132-0.426 0.273-0.629 0.422l-1.761-1.219-1.414 1.414 1.219 1.761c-0.149 0.203-0.29 0.413-0.422 0.629l-2.092-0.452-0.765 1.848 1.799 1.159c-0.059 0.244-0.109 0.492-0.148 0.743l-2.106 0.383v2l2.106 0.383c0.039 0.251 0.088 0.499 0.148 0.743l-1.799 1.159 0.765 1.848 2.092-0.452c0.132 0.216 0.273 0.426 0.422 0.629l-1.219 1.761 1.414 1.414 1.761-1.219c0.203 0.149 0.413 0.29 0.629 0.422l-0.452 2.092 1.848 0.765 1.159-1.799c0.244 0.059 0.492 0.109 0.743 0.148l0.383 2.106h2l0.383-2.106c0.251-0.039 0.499-0.088 0.743-0.148l1.159 1.799 1.848-0.765-0.452-2.092c0.216-0.132 0.426-0.273 0.629-0.422l1.761 1.219 1.414-1.414-1.219-1.761c0.149-0.203 0.29-0.413 0.422-0.629l2.092 0.452 0.765-1.848-1.799-1.159c0.059-0.244 0.109-0.492 0.148-0.743l2.106-0.383zM21 15.35c-2.402 0-4.35-1.948-4.35-4.35s1.948-4.35 4.35-4.35 4.35 1.948 4.35 4.35c0 2.402-1.948 4.35-4.35 4.35z"></path>
</svg>;

const maxValueZoom = 19;

let eventMapUpdate = new CustomEvent("MapUpdate", {
  detail: { id: "0" }
});
//document.dispatchEvent(eventMapUpdate);

function changeMarkers (arrOfMarkers, colors, map) {
  console.log('changeMarkers вызвана');
  console.log(arrOfMarkers);


  for (let elem of arrOfMarkers) {
    elem.remove();

  }
  console.log(colors);
  arrOfMarkers.length = 0;
  /*
  здесь и далее нужно внимательно смотреть за операциями с arrOfMarkers. 
  Очень важно чтобы arrOfMarkers ссылалась на this.refMarkers, и эту ссылку 
  не потеряла. Это также важно и для других данных из параметров этой функции.
  */


  for (let i = 0; i < HARITA_DATA["LISTE"].length; i++) {
    let consilienceOfColors = colors.has(HARITA_DATA["KONUMLAR"][i]["RENK"]);
    if (!consilienceOfColors) {
      let markers = new L.MarkerClusterGroup({iconCreateFunction: function (cluster) {
        let newDiv0 = document.createElement("div");
        newDiv0.innerHTML = String(i);
        newDiv0.setAttribute("style", `background-color: ${HARITA_DATA["KONUMLAR"][i]["RENK"]}; padding-left: 9px; border-radius: 50%;`); 
  
        return L.divIcon({ 
            html: newDiv0,
            className: 'mycluster',
            iconSize: L.point(30, 30) // размер иконки
        });
        }
      }); 
  
      for (let j = 0; j < HARITA_DATA["KONUMLAR"].length; j++) {
        if (HARITA_DATA["KONUMLAR"][j]["RENK"] == HARITA_DATA["LISTE"][i]["RENK"]) {
          let newDiv = document.createElement("div");
          newDiv.innerHTML = String(j);
          newDiv.setAttribute("style", `background-color: ${HARITA_DATA["KONUMLAR"][i]["RENK"]}; padding-left: 9px; border-radius: 50%;`); 
    
          markers.addLayer(
            L.marker([HARITA_DATA["KONUMLAR"][j]["ENLEM"], HARITA_DATA["KONUMLAR"][j]["BOYLAM"]], {icon: L.divIcon({className: 'my-div-icon', html: newDiv, iconSize: [30, 30]})})
          );
        }
      }
      arrOfMarkers.push(markers);
      map.addLayer(markers);
    }
  }
}

class Map extends React.Component {
             
  constructor(props) {
      super(props);
      this.state = {
        availabilityOfMeaning: 0,
        dialogWindow: false,
        renderThisComponent: [0],
        colors: [],
      };
      this.refMap = null;
      this.refReturnMap = null;
      this.refColors = new Set;
      this.refMarkers = [];
      this.refСustomControl = 0;
      this.refMarker = [];
      this.refSocket = 0;
      //this.listenFunc = this.listenFunc.bind(this);
      this.listenFunc = this.listenFunc.bind(this);
  }

 
  componentDidMount() {
    document.addEventListener("MapUpdate", this.listenFunc);

    let div = document.createElement("div");
    div.setAttribute('id', 'map'); 
    div.setAttribute('style', 'width: 100vw; height: 90vh'); 
    document.getElementsByClassName(this.props.class)[0].append(div);

    this.refMap = L.map('map').setView([HARITA_DATA["KONUMLAR"][0]["ENLEM"], HARITA_DATA["KONUMLAR"][0]["BOYLAM"]], 13);
    
      L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        maxZoom: maxValueZoom,
        subdomains:['mt0','mt1','mt2','mt3'],
      }).addTo(this.refMap);  
      


      let cluster = new L.MarkerClusterGroup({iconCreateFunction: function (cluster) {
        /*
                var childCount = cluster.getChildCount();
        var c = ' marker-cluster-';
        if (childCount < 10) {
            c += 'small';
        } else if (childCount < 100) {
            c += 'medium';
        } else {
            c += 'large';
        }
        */
        var markers = cluster.getAllChildMarkers();
        var html = '<div>' + markers.length + '</div>';
        return L.divIcon({ html: html, className: 'clusterUnit', iconSize: L.point(32, 32) });
      },
      spiderfyOnMaxZoom: false, showCoverageOnHover: false, zoomToBoundsOnClick: true, disableClusteringAtZoom: maxValueZoom});
      /*
      spiderfyOnMaxZoom - если есть максимальное увеличение, то клик на кластер выведет содержимое, но неточно
      showCoverageOnHover - при наведении на кластер показывает линии, которые соединяют элементы
      zoomToBoundsOnClick - при клике на кластер, он приближается на 1 уровень
      По умолчанию все свойства true.
      disableClusteringAtZoom - указывает уровень увеличения, на котором все кластеры отключаются. 
      В данном случае выставлен максимальный уровень увеличения 20.
      */


      /*
        let carConteiner = document.createElement("div");
        let carPlate = document.createElement("div");
        carPlate.innerText = MapRepository.data.GUNCEL_TAKIP[0].PLAKA;
        carPlate.setAttribute('class', 'carPlate'); 
        carPlate.setAttribute('style', `background-color: ${MapRepository.data.GUNCEL_TAKIP[0].RENK};`); 

        carConteiner.appendChild(carPlate);
    
        let arrowOfCar = document.createElement("canvas");
        //arrowOfCar.setAttribute('class', 'myCar-div-icon'); 
        //arrowOfCar.setAttribute('style', `width: 200px; height: 200px;`); 

        //arrowOfCar.setAttribute('style', `transform: rotate(${MapRepository.data.GUNCEL_TAKIP[i].YON}deg); background-color: ${MapRepository.data.GUNCEL_TAKIP[i].RENK};`); 
        let ctx = arrowOfCar.getContext('2d');
        ctx.fillStyle = MapRepository.data.GUNCEL_TAKIP[0].RENK;
        //ctx.fillStyle = 'blue';

        let height1 = 200;
        let height2 = 50;

        function animate() {
          ctx.clearRect(0, 0, 200, 200);

          if (height1 >= -100) {
            height1--;
          } else {
            height1 = 200;
          }

          ctx.beginPath();
          ctx.moveTo(20, height1 + 100);
          ctx.lineTo(50, height1 + 70);
          ctx.lineTo(80, height1 + 100);
          ctx.lineTo(50, height1 + 0);
          ctx.closePath();
          ctx.fill();

          if (height2 >= -100) {
            height2--;
          } else {
            height2 = 200;
          }

          ctx.beginPath();
          ctx.moveTo(20, height2 + 100);
          ctx.lineTo(50, height2 + 70);
          ctx.lineTo(80, height2 + 100);
          ctx.lineTo(50, height2 + 0);
          ctx.closePath();
          ctx.fill();
          window.requestAnimationFrame(animate);

        }



        carConteiner.appendChild(arrowOfCar);

        animate();

    
        console.log('carConteiner', carConteiner);
    
        this.refMarkers[0] = L.marker([MapRepository.data.GUNCEL_TAKIP[0].ENLEM, MapRepository.data.GUNCEL_TAKIP[0].BOYLAM], {icon: L.divIcon({className: 'carConteiner', html: carConteiner, iconSize: [30, 30]})}).bindPopup('<p style="font-size: 18px;">' + MapRepository.data.GUNCEL_TAKIP[0].PLAKA + '</p>');
        cluster.addLayer(this.refMarkers[0]);
      */

      
      for (let i = 0; i < MapRepository.data.GUNCEL_TAKIP.length; i++ ) {
        //let carConteiner = document.createElement("div");
        //carConteiner.setAttribute('style', 'width: auto; height: auto;'); 
        let selectedBlock = this.props.selectedBlock;
        let MapWrappingUpdate = this.props.MapWrappingUpdate;


        let carPlate = document.createElement("div");
        carPlate.innerText = MapRepository.data.GUNCEL_TAKIP[i].PLAKA;
        carPlate.setAttribute('class', 'carPlate'); 
        carPlate.setAttribute('style', `background-color: ${MapRepository.data.GUNCEL_TAKIP[i].RENK};`); 

        //carConteiner.appendChild(carPlate);

        carPlate.ondblclick = function () {
          console.log('arrowOfCar index', i);
          selectedBlock[1] = i;
          MapWrappingUpdate([0]);
        };
    
        let arrowOfCar = document.createElement("canvas");
        //arrowOfCar.setAttribute('class', 'myCar-div-icon'); 
        arrowOfCar.setAttribute('style', `width: 40px; height: 30px; transform: rotate(${MapRepository.data.GUNCEL_TAKIP[i].YON}deg);`); 

        //arrowOfCar.setAttribute('style', `transform: rotate(${MapRepository.data.GUNCEL_TAKIP[i].YON}deg); background-color: ${MapRepository.data.GUNCEL_TAKIP[i].RENK};`); 
        let ctx = arrowOfCar.getContext('2d');
        ctx.fillStyle = MapRepository.data.GUNCEL_TAKIP[i].RENK;

        let height1 = 200;
        let height2 = 50;

        function animate() {
          ctx.clearRect(0, 0, 200, 200);

          if (height1 >= -100) {
            height1--;
          } else {
            height1 = 200;
          }

          ctx.beginPath();
          ctx.moveTo(20, height1 + 100);
          ctx.lineTo(50, height1 + 70);
          ctx.lineTo(80, height1 + 100);
          ctx.lineTo(50, height1 + 0);
          ctx.closePath();
          ctx.fill();

          if (height2 >= -100) {
            height2--;
          } else {
            height2 = 200;
          }

          ctx.beginPath();
          ctx.moveTo(20, height2 + 100);
          ctx.lineTo(50, height2 + 70);
          ctx.lineTo(80, height2 + 100);
          ctx.lineTo(50, height2 + 0);
          ctx.closePath();
          ctx.fill();
          window.requestAnimationFrame(animate);

        }



        //carConteiner.appendChild(arrowOfCar);

        animate();

    
        //console.log('carConteiner', carConteiner);

        arrowOfCar.ondblclick = function () {
          console.log('arrowOfCar index', i);
          selectedBlock[1] = i;
          MapWrappingUpdate([0]);
        };
        this.refMarkers[i] = L.marker([MapRepository.data.GUNCEL_TAKIP[i].ENLEM, MapRepository.data.GUNCEL_TAKIP[i].BOYLAM], {icon: L.divIcon({className: 'carConteiner',html: arrowOfCar, iconSize: [30, 30]})})
        .bindPopup('<p style="font-size: 18px;" onclick="arrowOfCar.click()" ondblclick="arrowOfCar.dblclick()" >' + MapRepository.data.GUNCEL_TAKIP[i].PLAKA + '</p>')
        .bindTooltip(carPlate, {
          interactive: true,
          permanent: true,
          direction: 'bottom',
          className: 'tooltip-carPlate',
          offset: L.point(0, 20),
          //opacity: 0.6,
        }).openTooltip();
        cluster.addLayer(this.refMarkers[i]);

        console.log('carConteiner', this.refMarkers[i]);


      }
      

      this.refMap.addLayer(cluster);



   


    



    this.refReturnMap = this.refMap?.toString();

    console.log('MapComponent MapRepository.data', MapRepository.data);

/*
    this.refSocket = new WebSocket(`wss://www.createch.com.tr:1210/socket.io/?session=${this.props.token}&yeni=true&EIO=3&transport=polling&t=P39iszq`);

    console.log('this.refSocket', this.refSocket);

    this.refSocket.onopen = function(e) {
      console.log("[open] Соединение установлено");
    };

    this.refSocket.onmessage = function(event) {
      console.log(`[message] Данные получены с сервера: ${event.data}`);
    };
*/



  }

  componentWillUnmount() {
    this.refMap.remove();
    document.removeEventListener("MapUpdate", this.listenFunc);


    /*
    this.refSocket.onclose = function(event) {
      if (event.wasClean) {
       console.log(`[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
      } else {
        // например, сервер убил процесс или сеть недоступна
        // обычно в этом случае event.code 1006
        console.log('[close] Соединение прервано');
      }
    };
    this.refSocket.onerror = function(error) {
      alert(`[error]`);
    };
    */

    MapRepository.data = 0;
    /*
    В работе react происходит ошибка. При повторном открытии карты, во время нажатия 
    на переключатель между картой, sidebar и таблицей, на значительное время экран становиится белым.
    Это происходит из за того, что в компоненте MapWrapping не всегда срабатывает вовремя useEffect, 
    который должен очищать хранилище. Поэтому в хранилище остаются данные, и, при первом нажатии на 
    переключатель, данные очищаются, происходит запрос на сервер. 
    Очистка данных при размонтировании карты решает эту проблему.
    */
  }
  componentDidUpdate(){
    console.log('компонент обновился');
    console.log("this.refColors", this.refColors);
    //this.refСustomControl.remove();
    //console.log('this.refСustomControl', this.refСustomControl._container.childNodes);
    this.refReturnMap = null;
    /*
      При обновлении функция render каждый раз возвращает результат. 
      Однако экземпляр карты является объектом и не удаляется при обновлении данного компонента. 
      Из за этого при обновлении компонента функция render может вернуть экземпляр одного объекта 
      карты больше одного раза, что вызывает ошибки при отображении карты. 
      Переменная this.refReturnMap записывает и возвращает объект карты один раз, а потом 
      при каждом обновлении записывает и возвращает null.
    */
  
    if (this.props.selectedBlock[0] != -1) {
      this.refMap.setView([MapRepository.data.GUNCEL_TAKIP[this.props.selectedBlock[0]].ENLEM, MapRepository.data.GUNCEL_TAKIP[this.props.selectedBlock[0]].BOYLAM], maxValueZoom);
      this.props.selectedBlock[0] = -1;
    }
  }

  listenFunc() {
    /*
    this.setState(function() {
      return {
        renderThisComponent: [0],
      };
    });
    */
    //changeMarkers (this.refMarkers, this.refColors, this.refMap);
  }


  render() {
      //return this.refMap?.toString();
      return this.refReturnMap;

  }
}
/*
Использование метода toString является хаком. В react возникает ошибка, если вернуть объект. 
Однако есть объект превратить в строку, то все получается. 
Примечательно, что ошибка возникает только при обновлении, а при первом рендеринге приблем нет. 
*/

export default Map;

//По какой то причине this.refMap в компоненте класса не проходит внутрь метода forEach
/*

Проблема с наличием нескольких экземпляров решена. Теперь карта обновляется без проблем. 
Однако проблема с присвоением css стилей маркерам остается. По какой то причине, если присвоить цвет 
иаркеру в js коде, то часть стилей в css файле перестает работать.
Вот почему размер шрифта и цвет текста по прежнему работают, а padding и border radius нет?

Проблема решилась после использования createElement и setAttribute, оказывается, что в L.divIcon можно 
вставлять также и уже созданный html элемент.
*/

/*
Задачи на 11.06.2024:

1. Нужно сделать контейнер с названиями и цветами в левом верхнем углу. 
Выполнено.

2. Нужно чтобы по клике на элемент контейнера появлялись и исчезали связанные с ним маркеры на 
карте. 

var layer = L.marker(latlng).addTo(map);
layer.addTo(map);
layer.remove();

L.DomUtil.create('div', 'info');

Как сделать удаление и добавление маркеров? 
Варианты: 
1. сделать все через обновление стейта. В таком случае при клике на табличку доюавляем значение 
в state, потом компонент обновляется, вся группа маркеров удаляется и создается в цикле заново, 
но уже отфильтрованным.

2. сделать все через addEventListener. В таком случае обновления компонента не будет происходить, 
но нужно будет удалить все прослушиватели событий. К тому же прослушиватели будут реагировать на все 
события click, или же надо будет делатьть кастомное событие. 

Через setState сделать вроде немного проще. 

добавляем к нужным элементам атрибут onClick
в callback изменяем state и добавляем в него данные, в этом случае цвет. 
Не получается положить в элемент функцию, которая обновляла бы компонент. 
Эта библиотека блокирует любое взаимодействие со всем, разве что кроме js.

делать через события тоже не хочется, так как делать это через addEventListener тоже скорее всего 
не получится. Нужно под каждый элемент сделать отдельную функцию, а потом при удалении элемента ее тоже 
удалить. К тому же элементов может быть хоть сотня, тогда производительность сильно упадет. 

Можно ли как нибудь скрыть от библиотеки хук?

Теперь нужно при обновлении компонента удалять старые элементы и создавать новые. 

Нет, проблема не решена, невозможно вставить метод react в любой элемент, который создается в 
leafleet. 

Однако можно попробовать вставить не функцию react, а создать и потом вызвать кастомное событие. 
На document поставить addEventListener и удалять его при размонтировании компонента. 
В случае срабатывания кастомного события вызывать перерисовку компонента. 


Возможно, нужно удалять экземпляр карты при каждом обновлении компонента. На данный момент 
в dom больше 10 div с id='map'.

18 + 7
*/

/*
Задачи на 12.06.2024:
1. сделать удаление и добавление маркеров. 
Как это сделать? 
Можно сделать функцию, в которую можно положить карту и массив с объектами-маркерами.
Далее с помощью метода remove удаляем объекты-маркеры и создаем их заново, но уже с фильтром. 

Почему нельзя просто удалить неподходящие маркеры? Потому что их еще нужно будет потом добавлять. 
по повторному клику на элемент с названием цвета. 


L.layerGroup([marker1, marker2])
    .addLayer(polyline)
    .addTo(map);
*/

/*

Способ обновления компонента из html элементов в карте. 
1. создаем кастомное событие.
2. создаем функцию listenFunc для обновления компонента.
3. создаем прослушиватель событий для document на кастомное событие и listenFunc.
4. Создаем ref. Значение обязательно должно быть ссылочным.
5. Создаем переменную, в которую записываем ссылку в ref.
6. навешиваем на нужные компоненты функцию по изменению значения переменной при событии click. 
    Нужно обратить внимание на способ изменения переменной, лучше использовать метод push.
7. при срабатывании события click вызываем кастомное событие и вызывается функция listenFunc, 
    которая обновляет компонент. 
8. в методе componentWillUnmount удаляем прослушиватель событий. 

Теперь при нажатии на нужный элемент изменяется переменная, а также изменяется значение ref, 
потому что значение ссылочное. Далее вызывается кастомное событие и срабатывает функция по 
обновлению компонента.

Примечание: необходимость такой сложной процедуры вызвана недостатком самой библиотеки leafleet. 
Данная библиотека не допускает срабатывания функций react в некоторых случаях. Вероятно это 
вызвано тем, что библиотека при создании элементов html использует что то вроде шаблона "фабрика", 
и создает объекты в своем коде на основе полученных данных. И react не импортирован в leafleet, 
это leafleet импортирована в проект react.

*/

/*
Обратите внимание на разницу в параметре «lyrs» в URL-адресе:

Hybrid: s,h;
Satellite: s;
Streets: m;
Terrain: p;

*/



//setLatLng(<LatLng> latlng) - Изменяет положение маркера на указанную точку.

/*
https://www.createch.com.tr:1210/socket.io/?session=3ae84a65-ba18-42af-9cc2-5b5bfc308fb0&yeni=true&EIO=3&transport=polling&t=P39iszq
*/

/*
Как показать, что машина движется?
Может сделать анимацию, в которой стрелка идет снизу вверх?
Выглядит неплохо, но как это реализовать?
Нужно чтобы стрелка в какой то момент полностью выходила с экрана. 
Нужно видимую часть чем то ограничить, чтобы стрелка при окончании 
анимации не телепортировалась в начало, чтобы это выглядело плавно. 

Нужно сделать div с overflow hidden. Далее нужно сделать два div в форме стрелки. 
Далее делаем анимацию, чтобы когда один элемент исчезал появлялся другой. 
Далее корректируем тайминги анимации. 
*/

/*

Нужно убрать лишние элементы и разместить стрелки с номерами машин на карте по полученным координатам. 
1. удаляем все лишние элементы
2. в цикле добавляем элементы на страницу по полученным координатам.

Нужно чтобы все маркеры объединялись в кластер, если находятся слишком близко друг к другу. 
Нужно чтобы при нажатии на кластер, карта приближалась до тех пор, пока кластер не исчезнет или 
счетчик не понизится на единицу.
*/


/*
Нужно добавить канвас вместо стрелок на карту, чтобы анимировать эти стрелки. 
Канвас добавляется, но есть проблемы: 
1. Функция, которая перерисовывает канвас, выполняется только 1 раз. 
2. заливка стрелки нв канвасе неправильная. 

можно сделать вне цикла canvas, а в цикле сделать копирование элемента?

*/

//bindtooltip
//bindTooltip с параметром permanent: true.

/*
Нужно связать вместе маркеры на карте и блоки в sidebar. 
Они уже связаны через индекс данных с сервера. 
Нужно при нажатии на блок перевести экран по координатам мпшины и сделать максимальное увеличение. 
При нажатии на стрелку машины, нужно выделить блок sidebar.

Можно сделать общее хранилище для индекса выделенного элемента между MapComponent 
и Right_Sidebar. 
Для отслеживания данных в функциональном компоненте использовать хук external,
а для отслеживания данных в классовом компоненте использовать событие. 
Чтобы при изменении хранилища не изменялся инициирующий компонент, можно 
сделать 2 свойства в хранилище. 

Пока непонятно, будет ли работать хук external с хранилищем. 

А почему нельзя просто использовать стейт в родительском компоненте? все равно же 
все компоненты и так будут в это время обновляться?

При нажатии на блок выбирается нужная стрелка на карте. 
Теперь нужно при нажатии на стрелку выбрать блок. 
Как это сделать? 
1. Нужно передать в компонент карты стейт, чтобы при нажатии происходил перерендеринг всех 
компонентов.
2. Через ref передаем в компонент индекс нужного блока. 
Чтобы не было путаницы, в этом случае индекс можно вставлять по индексу 1?

Можно сделать проверку на наличие ссылки в компоненте. 
Но ведь возможна ситуация, когда на стрелку нажмут в то время, когда еще нет sidebar.
В таком случае можно вызвать sidebar и в queueMicrotask выделить нужный блок. 



*/



/*
    
      L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {

        maxZoom: 20,
        subdomains:['mt0','mt1','mt2','mt3']
      }).addTo(this.refMap);  

  это старый вариант запроса к google. Из за того, что google maps платные было принято 
  решение использовать карты с ресурса open street map. 
  Не удалять этот комментарий. 
*/
